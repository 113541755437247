@import url("./css/satoshi.css");
@import url('https://fonts.googleapis.com/css2?family=Inter:wght@100..900&display=swap');

@tailwind base;
@tailwind components;
@tailwind utilities;

:root {
    --Dark-Blue-60: #667085;
    --Dark-Blue-80: #1D2939;
    --Primary-Blue-10: rgba(0, 133, 255, 0.1);
    --Primary-Blue-100: rgba(0, 133, 255, 1);
    --White: rgba(255, 255, 255, 1);
    --White-40: rgba(255, 255, 255, 0.4);
    --White-80: rgba(255, 255, 255, 0.8);
}

* {
    margin: 0;
    padding: 0;
    cursor: default;
}

body {
    margin: 0;
    padding: 0;
    background-color: #FFFFFF;
    font-family: "Inter", sans-serif;
    font-style: normal;
    /*font-family: "Open Sans", sans-serif;*/
    opacity: 1 !important;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    width: 100vw;
    height: 100vh;
    /*overflow: hidden;*/
    overflow-x: hidden;
    /* overflow-y: hidden; */
}

.LandingBackground {
    opacity: 0.8;
    filter: blur(60px);
}

.Fade {
    background: linear-gradient(180deg, rgba(241, 241, 241, 0.00) 88.07%, #F1F1F1 100%);
}

.Noise {
    background-blend-mode: overlay;
    background: url('./assets/noise.png');
}

.Card {
    border-radius: 12px;
    background-color: white;
    border: 1px solid var(--White-80, rgba(255, 255, 255, 0.80));
    /* background: var(--White-40); */
    padding: 20px;
    /* Blur 16 */
    backdrop-filter: blur(8px);
}

.Navbar {
    border: 1px solid var(--White-80, rgba(255, 255, 255, 0.80));
    border-top: none;
    /* background: var(--White-40, rgba(255, 255, 255, 0.40)); */
    backdrop-filter: blur(10px);
}



.Heading {
    font-size: 36px;
    font-style: normal;
    font-weight: 600;
    line-height: 50.4px;
    color: var(--Dark-Blue-80);

    @apply max-lg:leading-[32px] text-center font-bold
}

.Linear {
    background: linear-gradient(96deg, rgba(0, 27, 51, 0.83) -13.01%, rgba(10, 123, 222, 0.83) 96.89%);
    background-clip: text;
    -webkit-background-clip: text;
    padding-bottom: 10px;
    -webkit-text-fill-color: transparent;
}

.Green {
    background-color: rgba(139, 240, 203, 0.16);
    display: flex;
    align-items: center;
    background-image: url('./assets/Green.svg');
    background-repeat: no-repeat;
    background-position: bottom left;
    background-size: 60%;
    height: 100%;
}

li::marker {
    color: var(--Dark-Blue-60);
}

input[type=number]::-webkit-inner-spin-button,
input[type=number]::-webkit-outer-spin-button {
    -webkit-appearance: none;
}

input[type=number] {
    -moz-appearance: textfield;
}

.Blue {
    background-color: rgba(155, 231, 255, 0.1);
    background-image: url('./assets/Blue.svg');
    background-repeat: no-repeat;
    background-position: bottom right;
    display: flex;
    flex-direction: column;
    justify-items: end;
    align-items: center;
    gap: 20px;
    padding: 50px;
    padding-top: 100px;
}

.Background2 {
    background: url('./assets/Background2.svg');
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
}

.Inner_Background2 {
    background: url('./assets/account-tree-top.svg'), url('./assets/account-tree-bottom.svg');
    background-position: top left, 90% 100%;
    background-repeat: no-repeat;
    background-size: 300, 300;
}

.Text {
    color: var(--Dark-Blue-80);
    font-size: 24px;
    font-style: normal;
    font-weight: 400;
    line-height: 135%;
    /* 32.4px */
}

.SubHeading {
    @apply text-lg font-bold text-Dark-Blue-80
}

.AuthoriseCard {
    background: linear-gradient(45deg, rgba(250, 252, 255, 1), rgba(240, 247, 255, 1));

    border-radius: 20px;
}

.GradientBorder {
    border: solid 1.5px transparent;
    border-radius: 20px;
    background-image: linear-gradient(white, white),
        linear-gradient(0deg, rgba(0, 133, 255, 0.2), rgba(0, 133, 255, 0));
    background-origin: border-box;
    background-clip: content-box, border-box;
}

.Button {
    display: inline-flex;
    padding: 16px 40px;
    flex-direction: column;
    align-items: flex-start;
    border-radius: 100px;
    background: var(--Primary-Blue-100);
    color: var(--White, #FFF);
    width: fit-content;
    font-size: 20px;
    font-style: normal;
    font-weight: 500;
    line-height: 26px;
}

.UpperEllipse {
    background-image: url('./assets/Back.svg');
    background-repeat: no-repeat;
    background-position: top center;
    background-size: cover;
}